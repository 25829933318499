import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Waiting from "ui-component/Waiting";
import RetailUserForm from "./RetailUserForm";
import { useNavigate } from "react-router-dom";

import { IndexRetailUser } from "store/retail/user/fetch";

export default () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      if (id) {
        setIsFetching(true);
        const response = await IndexRetailUser(id);
        setIsFetching(false);
        if (response) {
          setData(response);
        } else {
          navigate(`/retail/users`);
        }
      }
    })();
  }, [id]);

  const onSuccess = () =>  navigate(`/retail/users`);
  const onClose = () =>  navigate(`/retail/users`);

  if (isFetching || (id && !data)) return <Waiting />;
  return <RetailUserForm data={data} onSuccess={onSuccess} onClose={onClose} />;
};
