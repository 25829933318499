import { lazy } from "react";
import { Navigate } from "react-router-dom";
// project imports
import MainLayout from "layout/MainLayout";
import MinimalLayout from "layout/MinimalLayout";
import Loadable from "ui-component/Loadable";
import RetailUser from "views/pages/retail/RetailUser";
import RetailUserForm from "views/pages/retail/RetailUserForm";
import RetailUserDetail from "views/pages/retail/RetailUserDetail";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const Departament = Loadable(lazy(() => import("views/pages/adm/Departament")));
const Store = Loadable(lazy(() => import("views/pages/adm/Store")));
const CategoryUnitMeasurement = Loadable(
  lazy(() => import("views/pages/adm/CategoryUnitMeasurement"))
);

const UnitMeasurement = Loadable(
  lazy(() => import("views/pages/adm/UnitMeasurement"))
);

const Product = Loadable(lazy(() => import("views/pages/adm/Product")));

const Transaccion = Loadable(
  lazy(() => import("views/pages/inventory/Transaccion"))
);

const Gerencia = Loadable(lazy(() => import("views/pages/inventory/Gerencia")));
const History = Loadable(lazy(() => import("views/pages/inventory/History")));

const TransaccionDetail = Loadable(
  lazy(() => import("views/pages/inventory/TransaccionDetail"))
);
// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/Login"))
);

const Profile = Loadable(
  lazy(() => import("views/pages/authentication/Profile"))
);
const ProfileDetail = Loadable(
  lazy(() => import("views/pages/authentication/ProfileDetail"))
);

const User = Loadable(lazy(() => import("views/pages/authentication/User")));

const UserDetail = Loadable(
  lazy(() => import("views/pages/authentication/UserDetail"))
);

const SalesOnline = Loadable(
  lazy(() => import("views/pages/sales/SaleOnlineRetail"))
);

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/auth/login" />,
    children: [
      {
        path: "/",
        element: <DashboardDefault />,
      },
      {
        path: "/adm/departaments",
        element: <Departament />,
      },
      {
        path: "/adm/stores",
        element: <Store />,
      },
      {
        path: "/adm/categoriesunitmeasurement",
        element: <CategoryUnitMeasurement />,
      },
      {
        path: "/adm/unitsmeasurement",
        element: <UnitMeasurement />,
      },
      {
        path: "/adm/products",
        element: <Product />,
      },
      {
        path: "/inventory/Transactions",
        element: <Transaccion />,
      },
      {
        path: "/inventory/Transactions/gerencia",
        element: <Gerencia />,
      },
      {
        path: "/inventory/Transactions/histories",
        element: <History />,
      },
      {
        path: "/inventory/Transactions/:id",
        element: <TransaccionDetail />,
      },
      {
        path: "/auth/profiles",
        element: <Profile />,
      },
      {
        path: "/auth/profiles/new",
        element: <ProfileDetail />,
      },
      {
        path: "/auth/profiles/:id",
        element: <ProfileDetail />,
      },
      {
        path: "/auth/users",
        element: <User />,
      },
      {
        path: "/auth/users/new",
        element: <UserDetail />,
      },
      {
        path: "/auth/users/:id",
        element: <UserDetail />,
      },
      {
        path: "/sales/online",
        element: <SalesOnline />,
      },
      {
        path: "/retail/users",
        element: <RetailUser />,
      },
      {
        path: "/retail/users/:id",
        element: <RetailUserDetail />,
      }
    ],
  },
  {
    path: "/auth",
    element: !isLoggedIn ? <MinimalLayout /> : <Navigate to="/" />,
    children: [
      {
        path: "/login",
        element: <AuthLogin />,
      },
    ],
  },
];

export default MainRoutes;
