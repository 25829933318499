import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import { Grid } from "@mui/material";

export default ({ data, onClick }) => {
  return (
    <Card
      onClick={() => onClick(data.id)}
      elevation={2}
      sx={{ cursor: "pointer" }}
    >
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: "#fdefee" }} aria-label="recipe" />}
        title={`${data.user}`}
        subheader={data.email}
      />
      <CardMedia
        component="img"
        height="180"
        image="/images/users.jpg"
        alt="users"
      />
      <CardContent sx={{ pt: 3, pl: 2 }}>
        <Grid container spacing={1}>
          <Grid item>
            {<Chip label={data.Store.storeName} color="success" />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
