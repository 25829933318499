import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// routes
import MainRoutes from './MainRoutes';

// ==============================|| ROUTING RENDER ||============================== //
const ThemeRoutes = () => {
    const auth = useSelector((state) => state.auth);
    return useRoutes(MainRoutes(!!auth.token));
};
export default ThemeRoutes;
