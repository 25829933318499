// material-ui
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// project imports
import NavGroup from "./NavGroup";
// import menuItem from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const sort = (a, b) => a.order - b.order;

const MenuList = () => {
  const { t } = useTranslation();
  const { modules } = useSelector((state) => state.auth);
  const mapModule = (module) => {
    const children = modules.filter(
      (item) => item.moduleId === module.id && item.isRender
    );
    if (children.length) {
      return {
        id: module.id,
        type: "group",
        title: t(`modules.${module.moduleName}`),
        children: children.sort(sort).map(mapModule),
      };
    }
    return {
      id: module.id,
      title: t(`modules.${module.moduleName}`),
      type: "item",
      url: `/${module.url}`,
      breadcrumbs: false,
      module,
    };
  };

  const items = modules
    .filter((item) => !item.moduleId && item.isRender)
    .sort(sort)
    .map(mapModule);
  const navItems = items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
