import i18n from "i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";

import esVE from "./translations/esVE.json";
// import enUS from "./translations/enUS.json";

const resources = {
  // enUS: {
  //   translation: enUS,
  // },
  esVE: {
    translation: esVE,
  },
};

i18n.use(intervalPlural).init({
  resources,
  fallbackLng: "esVE",
  debug: true,
  lng: "esVE",
  react: {
    wait: true,
  },
});

export default i18n;
