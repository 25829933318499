// material-ui
import { useTheme } from "@mui/material/styles";
import logo from "assets/images/logo.svg";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => <img src={logo} alt="SuperLider" width="100" />;

export default Logo;
