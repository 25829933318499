import React, { useEffect, useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useTranslation } from "react-i18next";
import { filtersRetailUser } from "store/retail/user/fetch";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    color: "#616161",
  }),
};

const FilterSource = ({ onChange, value }) => {
  const [t] = useTranslation();

  const [optionsItems, setOptionsItems] = useState({});

  useEffect(async () => {
    if (optionsItems.resolve) {
      const timer = setTimeout(async () => {
        const stringLike = optionsItems.inputValue.toUpperCase();
        const data = await filtersRetailUser({
          search: stringLike,
        });

        optionsItems.resolve(data);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [optionsItems]);

  return (
    <AsyncCreatableSelect
      value={value}
      styles={customStyles}
      defaultOptions
      placeholder="Usuarios"
      formatCreateLabel={(input) => `Buscar por: ${input}`}
      onChange={onChange}
      isMulti
      className="basic-multi-select"
      classNamePrefix="select"
      loadOptions={(inputValue, resolve) =>
        setOptionsItems({
          inputValue,
          resolve,
        })
      }
    />
  );
};

FilterSource.defaultProps = {
  onChange: () => {},
  fieldsLike: [],
};

export default FilterSource;
