import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from './constant';

export const initialState = {
    token: null,
    modules: [],
    user: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return action.payload;
        case LOGOUT_SUCCESS:
            localStorage.removeItem('token');
            return initialState;
        default:
            return state;
    }
};
