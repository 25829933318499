import IconButton from "@mui/material/Fab";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const fabStyle = {
  position: "fixed",
  bottom: 80,
  right: 40,
};

export default function BtnNew({ onClick }) {
  return (
    <IconButton size="small" onClick={onClick} sx={fabStyle} aria-label={"Add"}>
      <MoreHorizIcon />
    </IconButton>
  );
}
