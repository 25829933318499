import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { EmailOutlined, PasswordOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import { toastr } from "react-redux-toastr";
import LinearProgress from "@mui/material/LinearProgress";

import { useTranslation } from "react-i18next";

import { CommandChangePasswordUser, PutRetailUser, SendEmailRetailUser } from "store/retail/user/fetch";

export default ({ data, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const [id, setId] = useState(null);
  const [email, setEmail] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (data) {
      setId(data.id);
      setEmail(data.email || "");
    }
  }, [data]);

  const handleSave = async () => {
    setIsSaving(true);
    const response = await PutRetailUser(id, {
      email,
    });
    setIsSaving(false);
    if (response) onSuccess();
  };

  const handleSendEmail = () => {
    SendEmailRetailUser(id)
    toastr.success(
      t(`common.attention`),
      t(`Correo Enviado`)
    );
  }

  const handleChangePassword = () => {
    CommandChangePasswordUser(id)
    toastr.success(
      t(`common.attention`),
      t(`Comando programado`)
    );
  }

  return (
    <>
      <Card elevation={2}>
        <CardHeader title={"Usuario Retail"} />
        {isSaving ? <LinearProgress color="secondary" /> : <Divider />}

        <CardContent>
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  value={data.user}
                  disabled={true}
                  fullWidth
                  id="user"
                  label="Usuario"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  value={data.Store.storeName}
                  disabled={true}
                  fullWidth
                  id="tienda"
                  label="Tienda"
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  onChange={({ target }) => setEmail(target.value)}
                  value={email}
                  fullWidth
                  id="email"
                  label="Correo"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  value={data.password}
                  disabled={true}
                  fullWidth
                  id="password"
                  label="Clave Actual"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>

        <Divider />
        <CardActions>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <IconButton onClick={handleSendEmail}>
                <EmailOutlined />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={handleChangePassword}>
                <PasswordOutlined />
              </IconButton>
            </Grid>
            <Grid item>
              {matchUpMd ? (
                <Button
                  onClick={onClose}
                  variant="outlined"
                  startIcon={<SettingsBackupRestoreIcon />}
                >
                  Cancelar
                </Button>
              ) : (
                <IconButton onClick={onClose}>
                  <SettingsBackupRestoreIcon />
                </IconButton>
              )}
            </Grid>

            <Grid item>
              <Button
                onClick={handleSave}
                variant="contained"
                endIcon={<SendIcon />}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </>
  );
};
