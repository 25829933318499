import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import authReducer from './auth';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    toastr: toastrReducer,
    auth: authReducer
});

export default reducer;
