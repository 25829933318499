import { useEffect, useState } from "react";
import { AppBar, Grid, Toolbar, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchIcon from "@mui/icons-material/Search";
import BtnMore from "ui-component/BtnMore";
import Waiting from "ui-component/Waiting";

import { useNavigate } from "react-router-dom";

// import ProductForm from "./ProductForm";
import CardUser from "component/retail/CardUser"
import FilterSource from "component/retail/SearchRetailUser";

import { fetchRetailUser } from "store/retail/user/fetch";

import { getQueryByFilter } from "utils";

const limit = 20;

export default () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState(null);
  const [viewItem, setViewItem] = useState(false);
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const [filter, setFilter] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [offset, setOffset] = useState(0);
  const [isGetMore, setIsGetMore] = useState(true);
  const navigate = useNavigate();

  const getData = async () => {
    if (offset === 0) setIsFetching(true);
    const response = await fetchRetailUser({
      ...getQueryByFilter(filter, ["user"]),
      limit,
      offset,
    });
    if (offset === 0) setIsFetching(false);
    setIsGetMore(response.length === limit);
    if (offset > 0) {
      return setData([...data, ...response]);
    }
    return setData(response);
  };

  useEffect(() => {
    const timer = setTimeout(async () => {
      (async () => {
        if (offset === 0) getData();
        else setOffset(0);
      })();
    }, 300);
    return () => clearTimeout(timer);
  }, [filter]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      (async () => {
        getData();
      })();
    }, 300);
    return () => clearTimeout(timer);
  }, [offset]);

  const handleClickCard = (idItem) => {
    navigate(`/retail/users/${idItem}`);
  };

  const getMore = () => {
    setOffset(offset + limit);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          classes={{ root: "borderRadios10" }}
          position="static"
          sx={{ mb: 2 }}
        >
          <Toolbar>
            {!matchUpMd && (
              <IconButton edge="start" color="inherit" aria-label="menu">
                <SearchIcon />
              </IconButton>
            )}

            <Typography
              variant="h6"
              noWrap
              component="div"
              color="white"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              USUARIOS EN RETAIL
            </Typography>
            <Box sx={{ flex: 1 }}>
              <FilterSource onChange={setFilter} value={filter} />
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      {isFetching ? (
        <Waiting />
      ) : (
        <Grid container spacing={4}>
          {data.map((element) => (
            <Grid key={element.id} item xs={12} md={4} lg={3}>
              <CardUser onClick={handleClickCard} data={element} />
            </Grid>
          ))}
        </Grid>
      )}
      {isGetMore && <BtnMore onClick={getMore} />}
    </>
  );
};
