import { createStore, applyMiddleware } from 'redux';
import reducer from './reducer';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2
};

const pReducer = persistReducer(persistConfig, reducer);

const store = createStore(pReducer, composeWithDevTools(applyMiddleware(reduxThunk)));
const persister = 'inventary';

export { store, persister };
