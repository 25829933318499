import axios from "axios";
import config from "config";
import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from "./constant";
import { headerAxiosBasic, headerAxiosAuth } from "utils";
import { toastr } from "react-redux-toastr";
import i18next from "i18next";

export const userLogin = (values) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${config.urlApi}/auth/login`, values, {
      headers: headerAxiosBasic,
    });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });
  } catch ({ response }) {
    toastr.warning(
      i18next.t(`common.attention`),
      i18next.t(`errorMsj.FAIL_LOGIN`)
    );
  }
};

export const userLogOut = () => (dispatch) => {
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const getModulesOperationes = async () => {
  try {
    const { data } = await axios.get(
      `${config.urlApi}/auth/module_operations`,
      {
        headers: headerAxiosAuth(localStorage.token),
      }
    );
    return data;
  } catch ({ response }) {
    return [];
  }
};
