import {
  headerAxiosAuth,
  objectToQuerystring,
  handleBadRequestMsj,
} from "utils";
import config from "config";
import axios from "axios";

export const filtersRetailUser = async (query) => {
  try {
    const { data } = await axios.get(
      `${config.urlApi}/softretail/users/filters${objectToQuerystring(query)}`,
      {
        headers: headerAxiosAuth(localStorage.token),
      }
    );
    return data;
  } catch ({ response }) {
    return [];
  }
};

export const fetchRetailUser = async (query = {}) => {
  try {
    const url =  `${config.urlApi}/softretail/users${objectToQuerystring(query)}`;
    console.info(url)
    const { data } = await axios.get(
      url,
      {
        headers: headerAxiosAuth(localStorage.token),
      }
    );
    return data;
  } catch ({ response }) {
    return [];
  }
};

export const IndexRetailUser = async (id) => {
  try {
    const { data } = await axios.get(`${config.urlApi}/softretail/users/${id}`, {
      headers: headerAxiosAuth(localStorage.token),
    });
    return data;
  } catch ({ response }) {
    return null;
  }
};

export const CommandChangePasswordUser = async (id) => {
  try {
    const { data } = await axios.post(
      `${config.urlApi}/softretail/users/${id}/change_password`,
      {},
      {
        headers: headerAxiosAuth(localStorage.token),
      }
    );
    return data;
  } catch ({ response }) {
    handleBadRequestMsj(response, "RetailUsers");
    return false;
  }
};

export const SendEmailRetailUser = async (id) => {
  try {
    const { data } = await axios.post(
      `${config.urlApi}/softretail/users/${id}/email`,
      {},
      {
        headers: headerAxiosAuth(localStorage.token),
      }
    );
    return data;
  } catch ({ response }) {
    handleBadRequestMsj(response, "RetailUsers");
    return false;
  }
};

export const PutRetailUser = async (id, values) => {
  try {
    const { data } = await axios.put(
      `${config.urlApi}/softretail/users/${id}`,
      values,
      {
        headers: headerAxiosAuth(localStorage.token),
      }
    );
    return data;
  } catch ({ response }) {
    handleBadRequestMsj(response, "RetailUsers");
    return false;
  }
};
