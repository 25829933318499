import i18next from "i18next";

import value from "assets/scss/_themes-vars.module.scss";
import { toastr } from "react-redux-toastr";
import { groupBy, reduce } from "lodash";

import config from "config";
import moment from "moment";
// has number
const hasNumber = (number) => new RegExp(/[0-9]/).test(number);

// has mix of small and capitals
const hasMixed = (number) =>
  new RegExp(/[a-z]/).test(number) && new RegExp(/[A-Z]/).test(number);

// has special chars
const hasSpecial = (number) => new RegExp(/[!#@$%^&*)(+=._-]/).test(number);

function getLocale() {
  return (navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language;
}

var locale = getLocale();

export const formatNumber = value => {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(Number(value));
};

// set color based on password strength
export const strengthColor = (count) => {
  if (count < 2) return { label: "Poor", color: value.errorMain };
  if (count < 3) return { label: "Weak", color: value.warningDark };
  if (count < 4) return { label: "Normal", color: value.orangeMain };
  if (count < 5) return { label: "Good", color: value.successMain };
  if (count < 6) return { label: "Strong", color: value.successDark };
  return { label: "Poor", color: value.errorMain };
};

export const isToday = (date) => moment().format("YYYY-MM-DD") == date;

export const renameImageUrl = (imageUrl) => {
  if (!!imageUrl && imageUrl.indexOf("/productImages") === 0) {
    return `${config.urlApi}${imageUrl}`;
  }
  return imageUrl;
};
export const getQueryByFilter = (values, fieldsLike = []) => {
  const _returnField = reduce(
    groupBy(
      values.filter((item) => !item.__isNew__),
      "field"
    ),
    (result, value, key) => {
      return {
        ...result,
        [key]: value.map((item) => item.value).join(","),
      };
    },
    {}
  );
  let _returnFieldLike = {};

  const _itemNew = values
    .filter((item) => item.__isNew__)
    .map((item) => item.value.toUpperCase());
  if (_itemNew.length) {
    _returnFieldLike = reduce(
      fieldsLike,
      (result, value) => ({
        ...result,
        [`${value}__like`]: _itemNew.join(","),
      }),
      {}
    );
  }
  return { ..._returnField, ..._returnFieldLike };
};

export const getRandomInt = (max = 99999) => {
  return Math.floor(Math.random() * max);
};

export const dow = [
  {
    id: -1,
    title: "-",
  },
  {
    id: 0,
    title: "DOMINGO",
  },
  {
    id: 1,
    title: "LUNES",
  },
  {
    id: 2,
    title: "MARTES",
  },
  {
    id: 3,
    title: "MIERCOLES",
  },
  {
    id: 4,
    title: "JUEVES",
  },
  {
    id: 5,
    title: "VIERNES",
  },
  {
    id: 6,
    title: "SABADO",
  },
];

// password strength indicator
export const strengthIndicator = (number) => {
  let strengths = 0;
  if (number.length > 5) strengths += 1;
  if (number.length > 7) strengths += 1;
  if (hasNumber(number)) strengths += 1;
  if (hasSpecial(number)) strengths += 1;
  if (hasMixed(number)) strengths += 1;
  return strengths;
};

export const headerAxiosAuth = (token) => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
});

export const headerMultipartAxiosAuth = (token) => ({
  Authorization: `Bearer ${token}`,
});

export const headerAxiosBasic = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};

export const objectToQuerystring = (obj) => {
  if (obj.id) {
    return `/${obj.id}`;
  }
  return Object.keys(obj).reduce(function (str, key, i) {
    var delimiter, val;
    delimiter = i === 0 ? "?" : "&";
    key = encodeURIComponent(key);
    val = encodeURIComponent(obj[key]);
    return [str, delimiter, key, "=", val].join("");
  }, "");
};

export const handleDeleteMsj = (response, model) => {
  if (response.status === 400) {
    response.data.forEach((item) => {
      if (item.errorKey === "SequelizeForeignKeyConstraintError") {
        toastr({
          title: i18next.t(`common.attention`),
          description: i18next.t(
            `errorMjsDelete.SequelizeForeignKeyConstraintError`
          ),
          status: "warning",
          isClosable: true,
          duration: 2000,
        });
      }
    });
  }

  if (response.status === 401) {
    toastr({
      title: i18next.t(`common.attention`),
      description: i18next.t(`errorMsj.UNAUTHORIZED`),
      status: "warning",
      isClosable: true,
      duration: 2000,
    });
  }
};

export const handleBadRequestMsj = (response, model) => {
  if (response.status === 401) {
    toastr.warning(
      i18next.t(`common.attention`),
      i18next.t(`errorMsj.UNAUTHORIZED`)
    );
    return;
  }
  if (response.status === 400) {
    const unique = response.data
      .filter((item) => item.errorKey === "unique")
      .map((item) => i18next.t(`${model}.${item.attr}`));
    if (unique.length) {
      if (unique.length == 1) {
        toastr.info(i18next.t("errorKey.unique", { field: unique[0] }));
      } else if (unique.length > 1) {
        toastr.info(
          i18next.t(`common.attention`),
          i18next.t("errorKey.unique_multiple", { fields: unique.join(", ") })
        );
      }
      return;
    }
    const FK = response.data.filter(
      (item) => item.errorKey === "SequelizeForeignKeyConstraintError"
    );
    if (FK.length) {
      toastr.info(i18next.t(`common.attention`), i18next.t("errorKey.fk"));
      return;
    }
    response.data
      .filter((item) => item.errorKey !== "unique")
      .forEach((item) => {
        toastr.info(
          i18next.t(`${model}.${item.attr}`),
          i18next.t(`errorKey.${item.errorKey}`)
        );
      });
  }
};
